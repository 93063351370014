.crt-share-box {
  padding-top: 13px;
}

.btn-share {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 15px 0 0;
  background: transparent;
  border-width: 2px;
  border-style: solid;
}

.btn-share,
.addthis_inline_share_toolbox {
  display: inline-block;
  vertical-align: top;
}

.crt-share-box {
  .addthis_inline_share_toolbox {
    opacity: 0;
    visibility: hidden;
  }

  .at-share-btn-elements {
    a {
      opacity: 0;
    }
  }

  &.hovered {
    .addthis_inline_share_toolbox {
      opacity: 1;
      visibility: visible;
    }

    .at-share-btn-elements {
      a {
        animation: FadeIn 250ms linear;
        animation-fill-mode: both;

        &:nth-child(1){ animation-delay: 0ms }
        &:nth-child(2){ animation-delay: 50ms }
        &:nth-child(3){ animation-delay: 100ms }
        &:nth-child(4){ animation-delay: 145ms }
        &:nth-child(5){ animation-delay: 175ms }
        &:nth-child(6){ animation-delay: 210ms }
      }
    }
  }

  &.closing {
    .at-share-btn-elements {
      a {
        animation: FadeOut 250ms linear;
        animation-fill-mode: both;

        &:nth-child(1){ animation-delay: 210ms }
        &:nth-child(2){ animation-delay: 175ms }
        &:nth-child(3){ animation-delay: 145ms }
        &:nth-child(4){ animation-delay: 100ms }
        &:nth-child(5){ animation-delay: 50ms }
        &:nth-child(6){ animation-delay: 0ms }
      }
    }
  }
}