/**
* WIDGETS
**/

/* General styles */

.screen-reader-text {
  display: none;
}

.widget {
  clear: both;
  display: block;
  margin-bottom: 35px;
  ul, ol {
    list-style: none;
    padding: 0;
    margin-left: 0;
    margin-bottom: 0;
    li {
      border-bottom: 1px solid transparent;
    }
  }
}

.widget-title {
  position: relative;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 45px;
}

/* Search */

.search-form {
  position: relative;
  width: 100%;

  .search-field {
    width: 100%;
    height: 50px;
    padding: 15px 45px 15px 15px;
    border: 0;
  }

  .search-submit {
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px;
    border: 0;
    background: transparent;
    display: inline-block;
    vertical-align: middle;
  }
}

/* Popular Posts */

.widget_posts_entries {
  li {
    display: block;
    overflow: hidden;
    margin-bottom: 15px;
    padding-bottom: 10px;
    .post-image {
      float: left;
      width: 78px;
      margin-right: 24px;
      opacity: 1;
    }
  }
}


.widget_posts_entries {
  li {
    h3 {
      margin: 0;
      display: block;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
    }
  }
}

.widget_posts_entries {
  a {
    display: block;
    font-weight: 600;
  }
}

.post-category-comment {
  clear: both;
  padding-top: 18px;
}

.post-category-comment {
  a {
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    line-height: 1;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid transparent;

    &:last-child {
      border-right: 0;
    }
  }
}

/* Tags */
.widget_tag_cloud {
  .tagcloud {
    overflow: hidden;

    a {
      position: relative;
      float: left;
      font-size: 15px ;
      font-weight: 700;
      margin: 0 6px 8px 0;
      padding: 5px 10px;
      border: 1px solid transparent;
      text-decoration: none;
    }
  }
}

/* Recent Posts */

.widget_recent_entries {
  li {
    padding: 25px 0;
    > a {
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      &:first-child {
        padding-top: 0;
      }
    }
  }
}

/* Post Categories */
.widget_categories {
  li {
    padding: 15px 0;

    &:first-child{
      padding-top: 0;
    }

    a {
      font-size: 14px;
      margin-right: 10px;
      text-decoration: none;
    }
  }
}