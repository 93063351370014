/**
 * Post
 */
.post-media {
  position: relative;
  margin-bottom: 3.250em;
}

.post-play,
.post-voice {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

// Media Type
.post-video,
.post-youtube,
.post-vimeo,
.post-dailymotion,
.post-soundcloud{
  height: 0;
  padding-bottom: 50%;
  position: relative;

  iframe,
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.post-audio{
  audio{
    width: 100%;
  }
}

.post-title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.post-header {
  margin-bottom: 2.188em;
}

.post-footer {
  margin-top: 3.1em;
}

.post-footer-top {
  padding-bottom: 1.15em;
}

.post-footer-btm {
  margin-top: 1.5em;
}

.post-header-info,
.post-footer-info {
  font-size: 0.813em;

  a, time, span {
    display: inline-block;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.post-footer-info {
  letter-spacing: 0.1em;
}

.post-line {
  margin: 0 10px;
}

.post-tags {
  a {
    font-size: 0.938em;
    font-weight: 700;
    line-height: 1.1em;
    letter-spacing: 0.02em;
    text-decoration: none;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    padding: 0.286em 1em;
    margin: 0 0.357em 0.357em 0;
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.117647) -1px 2px 6px, rgba(0, 0, 0, 0.117647) -1px 2px 4px;
    }
  }
}

/* Post Navigation */
.post-nav {
  margin-top: 4.45em;
}

.post-nav-prev,
.post-nav-next {
  padding-bottom: 1.750em;

  a {
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }
  }

  a, span, strong, figure {
    display: block;
  }

  span {
    font-size: 1.08em;
    font-weight: 700;
    letter-spacing: 0.11em;
    margin-bottom: 0.813em;
  }

  strong {
    font-size: 0.95em;
    line-height: 1.38em;
  }

  figure {
    margin-bottom: 0.9em;
  }

  img {
    display: block;
    width: 100%;
  }
}


@media (max-width: $screen-sm - 1) {
  .post-nav-prev,
  .post-nav-next {
    font-size: 0.8em;

    .text-left,
    .text-right {
      text-align: center;
    }
  }
}

/* Post Comments */
.comments-area,
.comment-respond {
  margin-top: 4.375em;
}

.comment-list {
  border-bottom: 1px solid transparent;

  &>li:first-child>.comment-body {
    border-top: none;
  }
}

.comment {
  .children {
    display: none;
  }

  &.show-replies {
    .children {
      display: block;
    }
  }
}

.comment-body {
  min-height: 58px;
  position: relative;
  border-top: 1px solid transparent;
  padding: 1.063em 0;
}

.comment-header {
  margin-bottom: 0.688em;

  .avatar {
    top: 1.063em;
    left: 0;
    position: absolute;
    border-radius: 50%;
  }
}

.comment-header,
.comment-links {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.comment-author {
  font-size: 0.933em;
  letter-spacing: 0;
  margin-bottom: 0.25em;
}

.comment-date {
  font-size: 0.72em;
}

.comment-footer {
  font-size: 0.82em;
  font-weight: 700;
  letter-spacing: 0.04em;

  a {
    margin-right: 5px;
  }
}

.comment-links,
.comment-replys-count {
  display: inline-block;
  vertical-align: middle;
  margin-top: 1.65em;
}

.bypostauthor > .comment-body .fn:after {
  content: "\f007";
  font-size: 0.85em;
  font-family: 'icomoon';
  position: relative;
  margin-left: 0.6em;
}


@media (min-width: $screen-sm) {
  .comment-list {
    ol {
      margin-left: 75px;
    }
  }

  .comment-body {
    padding-left: 75px;
  }

  .comment-links {
    position: absolute;
    right: 0;
    top: 1.063em;
    margin-top: 0;
  }

  .comment-header {
    padding-right: 5.4em;
  }
}

@media (max-width: $screen-sm - 1) {
  .comment-header {
    min-height: 58px;
    padding-left: 65px;
  }
}


/* Category Page */
.category {
  .post {
    margin-bottom: 2.813em;
  }

  .post-footer {
    text-align: center;
  }

  .post-footer-top {
    padding-bottom: 2.8em;
  }

  .post-footer-info {
    margin-bottom: 1.5em;
  }
}

@media (min-width: $screen-sm) {
  .category {
    .post-footer-info {
      float: left;
      margin-bottom: 0;
    }

    .post-more {
      float: right;
    }
  }
}
