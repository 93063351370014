/**
 * Theme Font Families
 */

// Main Font
body {
  font-family: $base-font-family;
}

// Headings Font
h1, h2, h3, h4, h5, h6 {
  font-family: $headings-font-family;
}

// Logo Font Family
.crt-logo {
  font-family: $logo-font-family;
}