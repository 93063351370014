@media(min-width:$screen-sm){
  .padd-box-xs{
    padding-left: 27%;
    padding-right: 17%;
  }
}

.contact-head{
  margin-bottom: 55px;
  .crt-social{
    margin-bottom: 45px;
  }
}

#map{
  width: 100%;
  padding-top: 41%;
  margin-top: 50px;
}