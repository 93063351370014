/**
 * Containers
 */
.crt-wrapper {
  overflow: hidden;
  position: relative;
}

.crt-container {
  width: 100%;
  margin: 0 auto;
}

.crt-container-sm {
  padding-left: 20px;
  padding-right: 20px;
}

.crt-paper-layers {
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  .crt-desktop & {
    &.crt-animate {
      visibility: hidden;
      opacity: 0;
      transform: translate3d(0, 150px, 0) scale3d(1, 1, 1);
      transition: transform 300ms ease-out, opacity 300ms ease-out, visibility 300ms ease-out;
    }

    &.crt-animated {
      opacity: 1;
      visibility: visible;
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      transition: transform 300ms ease-out, opacity 300ms ease-out, visibility 300ms ease-out;
    }
  }
}

.crt-paper {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;

  .paper-padd {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: $screen-md) {
  .crt-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .crt-container {
    max-width: $container;
  }

  .crt-container-sm {
    padding-left: 0;
    padding-right: 0;
  }

  #crt-container {
    background-color: transparent !important;
  }

  // Paper Layers
  .crt-paper-layers {
    position: relative;
    z-index: 0;

    .crt-layers-2 & {
      margin-bottom: 3px;
    }

    .crt-layers-3 & {
      margin-bottom: 6px;
    }

    .crt-layers-2 &:before,
    .crt-layers-3 &:before,
    .crt-layers-3 &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .crt-layers-2 &:before,
    .crt-layers-3 &:before {
      top: 3px;
      left: 3px;
      z-index: 2;
    }

    .crt-layers-3 &:after {
      top: 6px;
      left: 6px;
      z-index: 1;
    }
  }

  .crt-paper,
  .crt-paper-layers:after,
  .crt-paper-layers:before {
    border: 1px solid transparent;
  }

  .crt-paper {
    z-index: 3;
    padding-left: 20px;
    padding-right: 20px;
  }

  // Tab Nav ON
  .crt-nav-on {
    .crt-container-sm {
      margin-right: $tab-nav-width;
    }

    .crt-container {
      max-width: $container + $tab-nav-width;
    }
  }

  // Side Box ON
  .crt-side-box-on {
    .crt-paper-cont {
      float: left;
      width: 100%;
    }

    .crt-container-sm {
      @if($side-box-space > 20px) {
        margin-left: $side-box-width + 20;
      } @else {
        margin-left: $side-box-width + $side-box-space;
      }
    }

    .crt-container {
      max-width: $container + $side-box-width + $side-box-space;
    }

    &.crt-nav-on {
      .crt-container {
        max-width: $container + $side-box-width + $side-box-space + $tab-nav-width;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .crt-side-box-on {
    .crt-container-sm {
      margin-left: $side-box-width + $side-box-space;
    }
  }
}

// Certy Section
.section {
  margin-bottom: 2.188em;

  &.brd-btm {
    padding-bottom: 1.375em;
  }

  @media (max-width: $screen-xs - 1){
    [class^="col-xs-"] {
      margin-bottom: 2em;
    }
  }

  @media (max-width: $screen-sm - 1){
    [class^="col-sm-"] {
      margin-bottom: 2em;
    }
  }

  @media (max-width: $screen-md - 1){
    [class^="col-md-"] {
      margin-bottom: 2em;
    }
  }

  @media (max-width: $screen-lg - 1){
    [class^="col-lg-"] {
      margin-bottom: 2em;
    }
  }
}

// Certy Padding Boxe's
@media (min-width: $screen-sm){
  .padd-box-sm {
    margin-left: 8%;
    margin-right: 8%;
  }
}

@media (min-width: $screen-md){
  .padd-box-sm {
    margin-left: 12%;
    margin-right: 12%;
  }
}

@media (min-width: $screen-lg) {
  .padd-box {
    margin-left: 3.4%;
    margin-right: 3.4%;

    .padd-box-sm {
      margin-left: 8.6%;
      margin-right: 8.6%;
    }
  }
}

