.tooltip {
  position: relative;
  border-bottom: 1px dotted;

  &:after {
    position: absolute;
    content: attr(data-tooltip);
    box-shadow: 0 2px 9px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    white-space: nowrap;
    text-align: center;
    color: #000;
    opacity: 0;
    left: 0;
    bottom: 125%;
    padding: 3px 7px;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
  }

  &:hover:after,
  &:hover:before {
    opacity: 1;
    visibility: visible;
  }

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 2;
    margin-left: -7px;
    border-width: 7px 7px 0 7px;
    border-style: solid;
    border-color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
  }
}